'use client';

import { AppContext, AppContextProvider } from '@/lib/store/context';
import React from 'react';

type ContextLayoutProps = React.PropsWithChildren<AppContext>;

export default function ContextLayout({ children, ...props }: ContextLayoutProps) {
  return <AppContextProvider value={props}>{children}</AppContextProvider>;
}
