import { createBlock } from '@/lib/parse/blocks';
import { Entry } from '@/lib/parse/entries';

/** Hook to apply card "overrides" and extra features */
export const useFeaturedNewsBlock = createBlock<
  'blocks_featuredNews_BlockType',
  NoProps,
  Entry<'article'>[] | null
>((props) => {
  const { articleMultiple: items } = props;

  // Bail early
  if (!items?.length) return null;

  return items as Entry<'article'>[];
});
