import * as styles from './Card.Inner.css';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import Txt, { TxtProps } from '@/polymorphics/Txt';

export type CardDescriptionProps<D extends React.ElementType = 'span'> = TxtProps<D>;

export const CardDescription = <D extends React.ElementType>({
  children,
  ...props
}: CardDescriptionProps<D>) => {
  const { description } = useCard();

  return (
    (children || description) && (
      <Txt as="span" variant="md" html {...mergePropsClassName(props, styles.description)}>
        {children || description}
      </Txt>
    )
  );
};
