import Flex, { FlexProps } from '@/polymorphics/Flex';
import Txt, { TxtProps } from '@/polymorphics/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import * as style from './BlockHeader.css';
import { BlockMeta } from '@/lib/parse/blocks';

export type BlockHeaderProps = FlexProps<
  'div',
  {
    children?: React.ReactNode;
    className?: string;
    heading?: React.ReactNode;
    HeadingProps?: TxtProps;
    content?: React.ReactNode;
    ContentProps?: TxtProps;
    meta?: BlockMeta;
  } & style.BlockHeaderVariants
>;

const BlockHeader = ({
  children,
  heading,
  HeadingProps,
  content,
  ContentProps,
  maxWidth,
  meta: _meta,
  ...props
}: BlockHeaderProps) => {
  if (!heading && !content) return null;

  return (
    <>
      <Flex {...mergePropsClassName(props, style.root({ maxWidth }))}>
        {heading && (
          <Txt
            variant="h2"
            as="h2"
            {...mergePropsClassName(HeadingProps, style.heading({ marginBottom: !!content }))}>
            {heading}
          </Txt>
        )}
        {content && (
          <Txt as="div" variant="md" {...ContentProps} html>
            {content}
          </Txt>
        )}
        {children}
      </Flex>
    </>
  );
};

export default BlockHeader;
