import { createBlock } from '@/lib/parse/blocks';
import { Entry } from '@/lib/parse/entries';

export const useCtaBlock = createBlock<
  'blocks_ctaBlock_BlockType',
  NoProps,
  Entry<'ctaBlock'>[] | null
>((props) => {
  const { ctaBlock: items } = props;

  // Bail early
  if (!items?.length) return null;

  return items as Entry<'ctaBlock'>[];
});
