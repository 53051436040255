import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/lib/parse/blocks';
import Box from '@/polymorphics/Box';
import Flex from '@/polymorphics/Flex';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockContainer } from '../../BlockContainer';
import IconCard from './IconCard';
import { useIconGrid } from './useIconGridBlock';

const IconGridBlock = createBlock<'blocks_iconGrid_BlockType'>(
  ({ _blockMeta: meta, heading, content, colorTheme, ...props }) => {
    const { items } = useIconGrid(props);
    const colored = toBoolean(colorTheme) || undefined;

    return (
      <BlockContainer {...{ meta, colorTheme, colorSet: 'primary', colored }}>
        <Box>
          <BlockHeader
            maxWidth="none"
            cx={{ mB: '7' }}
            heading={heading}
            HeadingProps={{ variant: 'h1', cx: { mX: 'auto' } }}
            content={content}
            ContentProps={{
              cx: { width: { desktop: 'twoThirds' }, textAlign: { desktop: 'center' }, mX: 'auto' },
            }}
          />
          {!!items.length && (
            <Flex cx={{ wrap: 'wrap', justifyContent: { desktop: 'center' }, rowGap: '6' }}>
              {items.map((item, idx) => {
                return (
                  <IconCard
                    cx={{
                      width: {
                        desktop: items.length > 3 && items.length < 5 ? 'quarter' : 'third',
                        tablet: 'half',
                      },
                      pX: { desktop: '2' },
                    }}
                    key={idx}
                    {...item}
                  />
                );
              })}
            </Flex>
          )}
        </Box>
      </BlockContainer>
    );
  }
);

export default IconGridBlock;
