'use client';

import { Block, filterBlocks } from '@/lib/parse/blocks';

import HeroStatementBlock from '@/components/Blocks/BlockComponents/HeroStatementBlock';
import ImageAndTextBlock from '@/components/Blocks/BlockComponents/ImageAndTextBlock';
import TestimonialBlock from '@/components/Blocks/BlockComponents/TestimonialBlock';
import RichTextBlock from '@/components/Blocks/BlockComponents/RichTextBlock';
import LeadershipTeamBlock from '@/components/Blocks/BlockComponents/LeadershipTeamBlock';
import VideoBlock from '@/components/Blocks/BlockComponents/VideoBlock/VideoBlock';
import TypeformBlock from '@/components/Blocks/BlockComponents/TypeformBlock';
import IconGridBlock from '@/components/Blocks/BlockComponents/IconGridBlock';
import StatsBlock from '@/components/Blocks/BlockComponents/StatsBlock';
import JobListingsBlock from '@/components/Blocks/BlockComponents/JobListingsBlock';
import QuoteBlock from '@/components/Blocks/BlockComponents/QuoteBlock';
import ImageBlock from './BlockComponents/ImageBlock';
import MediaGalleryBlock from './BlockComponents/MediaGalleryBlock';
import { assertUnreachable } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockProvider, BlocksContext, BlocksContextProvider } from './BlockContainer/useBlock';
import { useViewBlocks } from '@/lib/store/hooks';

import CtaBlock from './BlockComponents/CtaBlock';
import FeaturedNewsBlock from './BlockComponents/FeaturedNewsBlock';
import AccordionBlock from './BlockComponents/AccordionBlock';
import TableBlock from './BlockComponents/TableBlock';
import PdfDownloadBlock from './BlockComponents/PdfDownloadBlock';
import CuratedArticlesBlock from '@/components/Blocks/BlockComponents/CuratedArticlesBlock';

export const BlockInner = (block: Block) => {
  switch (block.__typename) {
    case 'blocks_accordion_BlockType':
      return <AccordionBlock {...block} />;
    case 'blocks_ctaBlock_BlockType':
      return <CtaBlock {...block} />;
    case 'blocks_featuredNews_BlockType':
      return <FeaturedNewsBlock {...block} />;
    case 'blocks_heroStatement_BlockType':
      return <HeroStatementBlock {...block} />;
    case 'blocks_iconGrid_BlockType':
      return <IconGridBlock {...block} />;
    case 'blocks_image_BlockType':
      return <ImageBlock {...block} />;
    case 'blocks_imageAndText_BlockType':
      return <ImageAndTextBlock {...block} />;
    case 'blocks_jobListings_BlockType':
      return <JobListingsBlock {...block} />;
    case 'blocks_leadershipTeam_BlockType':
      return <LeadershipTeamBlock {...block} />;
    case 'blocks_richText_BlockType':
      return <RichTextBlock {...block} />;
    case 'blocks_stats_BlockType':
      return <StatsBlock {...block} />;
    case 'blocks_testimonial_BlockType':
      return <TestimonialBlock {...block} />;
    case 'blocks_typeform_BlockType':
      return <TypeformBlock {...block} />;
    case 'blocks_video_BlockType':
      return <VideoBlock {...block} />;
    case 'blocks_curatedArticles_BlockType':
      return <CuratedArticlesBlock {...block} />;
    case 'blocks_quote_BlockType':
      return <QuoteBlock {...block} />;
    case 'blocks_mediaGallery_BlockType':
      return <MediaGalleryBlock {...block} />;
    case 'blocks_table_BlockType':
      return <TableBlock {...block} />;
    case 'blocks_pdfDownload_BlockType':
      return <PdfDownloadBlock {...block} />;
    case 'blocks_accordionItem_BlockType':
      return null;
    case 'blocks_iconCard_BlockType':
      return null;
    case 'blocks_jobListing_BlockType':
      return null;
    case 'blocks_stat_BlockType':
      return null;
    default:
      return assertUnreachable(block);
  }
};

type BlocksProps = {
  blocks?: Block[];
} & BlocksContext;

const Blocks = ({ blocks: maybeBlocks, ...context }: BlocksProps) => {
  const viewBlocks = useViewBlocks();

  const blocks = maybeBlocks ? filterBlocks(maybeBlocks).blocks : viewBlocks.blocks;

  const blocksOutput = blocks.map((v, i) => (
    <BlockProvider key={i + 1} value={v}>
      <BlockInner {...v.block} />
    </BlockProvider>
  ));

  return blocks.length ? (
    <BlocksContextProvider value={context}>{blocksOutput}</BlocksContextProvider>
  ) : (
    <></>
  );
};

export const BlockChildren = Blocks;

export default Blocks;
