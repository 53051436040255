import { Breakpoint, breakpoints } from '@/style/utils/breakpoints';
import { useEffect, useState } from 'react';

type BreakpointCheck = (bp: Breakpoint) => boolean;

const useViewportWidth = (): [BreakpointCheck, BreakpointCheck, number] => {
  const [viewportWidth, setViewportWidth] = useState(0);

  useEffect(() => {
    setViewportWidth(window.innerWidth);

    window.addEventListener('resize', () => {
      setViewportWidth(window.innerWidth);
    });
  }, [setViewportWidth]);

  const breakpointMin = (bp: Breakpoint) => viewportWidth >= breakpoints[bp];
  // eg. breakpointMax(md) = everything up to md ~900px
  const breakpointMax = (bp: Breakpoint) => viewportWidth < breakpoints[bp];

  return [breakpointMin, breakpointMax, viewportWidth];
};

export default useViewportWidth;
