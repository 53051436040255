import { Skeleton, SkeletonProps } from '@mui/material';
import { CardItem } from '../CardGrid';
import SimpleGrid, { SimpleGridProps } from '../SimpleGrid';
import Flex, { FlexProps } from '@/polymorphics/Flex';

type SkeletonCards = {
  amount: number;
  SkeletonItems: SkeletonCard;
} & Omit<SimpleGridProps, 'children'>;

const SkeletonCards = ({ amount, SkeletonItems, ...props }: SkeletonCards) => {
  const items: CardItem[] = Array.from({ length: amount }, (_, i) => ({ id: i }));

  return amount > 1 ? (
    <SimpleGrid rowGutter {...props}>
      {items.map((_, i) => {
        return <SkeletonCard key={i} {...SkeletonItems} />;
      })}
    </SimpleGrid>
  ) : (
    <SkeletonCard {...SkeletonItems} />
  );
};

type SkeletonCard = FlexProps<
  'div',
  {
    items: SkeletonProps[];
  }
>;

const SkeletonCard = ({ items = [], ...props }: SkeletonCard) => {
  return (
    <Flex cx={{ rowGap: '2' }} {...props}>
      {items.map((item, i) => {
        return <Skeleton key={i} animation="wave" {...item} />;
      })}
    </Flex>
  );
};

export default SkeletonCards;
