/* eslint-disable @typescript-eslint/no-explicit-any */
import { sanitiseAnything, SanitisedElement } from '@/lib/parse/sanitiseElements';
import { firstNonNullable, makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import * as Typed from '@liquorice/allsorts-craftcms-nextjs/lib/sanitiser/sanitiserTypes';
import { EntriesFragment, EntryCardsFragment } from '@/__generated__/graphql';

/**
 * @example type Entry {
 *  __typename: 'page_Entry'
 *  sectionId: 'page',
 *  typeAlias: 'page',
 * }
 * @example type Entry {
 *  __typename: 'page_overview_Entry'
 *  sectionId: 'page',
 *  typeAlias: 'pageOverview',
 * }
 */

export type PageEntryTypes =
  | Entry<'article'>
  | Entry<'standard'>
  | Entry<'overview'>
  | Entry<'articleIndex'>
  | Entry<'home'>;

/**
 * Union of top level "Entries" defined with a '__typename'
 */
type RawEntries = EntriesFragment | EntryCardsFragment;

/**
 * __typename of top level Entry
 */
export type EntryTypename = Typed.Typename<RawEntries>;

/**
 * Inferred 'sectionId' from {@link EntryType}
 * Matches 'section' argument in Entry queries
 */
export type EntryTypeId<T extends EntryTypename = EntryTypename> = T extends `${infer S}_Entry`
  ? S
  : never;

type EntryTypeIdToTypename<T extends EntryTypeId> = Extract<EntryTypename, `${T}_Entry`>;

// ----------------------------------------------------------------------------------------------------
// ---- Extracted sanitised types ----

export type SanitisedEntry<T extends EntryTypename = EntryTypename> = SanitisedElement<T>;

// ----------------------------------------------------------------------------------------------------

/**
 * Sanitise a single {@link EntriesFragment} through the {@link sanitiseAnything}`.one()` function
 * Limit the valid return Type by providing a single or array of {@link EntryTypename}
 */
const sanitiseEntry = <T extends EntriesFragment, Name extends EntryTypename = Typed.Typename<T>>(
  maybeEntry?: T | null,
  typeNames?: MaybeArrayOf<Name>
) => (maybeEntry ? (sanitiseAnything.one(maybeEntry, typeNames) as SanitisedEntry<Name>) : null);

/**
 * Augments the result of {@link sanitiseEntry}
 */
const parseSanitisedEntry = <T extends EntryTypename>(sanitisedEntry: SanitisedEntry<T> | null) => {
  if (!sanitisedEntry) return null;
  return { ...(sanitisedEntry as SanitisedEntry<T>) };
};

/**
 * Sanitise and parse a single  {@link EntriesFragment}
 */
export const parseEntry = <
  T extends EntriesFragment,
  Name extends EntryTypename = Typed.Typename<T>
>(
  maybeEntry?: MaybeArrayOf<T>,
  typeNames?: MaybeArrayOf<Name>
) => {
  const typeNamesArr = makeNonNullableArray(typeNames);
  const sanitisedEntry = sanitiseEntry(
    firstNonNullable(maybeEntry),
    typeNamesArr.length ? typeNamesArr : undefined
  );
  return sanitisedEntry ? parseSanitisedEntry<Name>(sanitisedEntry) : null;
};

/**
 * Sanitise and parse multiple {@link EntriesFragment}
 */
export const parseEntries = <
  T extends EntriesFragment,
  Name extends EntryTypename = Typed.Typename<T>
>(
  maybeEntries: MaybeArrayOf<T>,
  typeNames?: MaybeArrayOf<Name>
) => {
  const rawEntriesArr = makeNonNullableArray(maybeEntries);
  const parsedEntries = rawEntriesArr.map((e) => parseEntry(e, typeNames));
  return makeNonNullableArray(parsedEntries);
};

export type Entry<T extends EntryTypeId = EntryTypeId> = Exact<
  Typed.ExtractByTypename<ReturnType<typeof parseEntry>, EntryTypeIdToTypename<T>>
>;

// ----------------------------------------------------------------------------------------------------
// ---- Type guards ----

interface IsEntryTypeGuard {
  (x: any): x is Entry;
  <T extends EntryTypeId>(x: any, typeId: T): x is Entry<T>;
}

export const isEntry: IsEntryTypeGuard = <T extends EntryTypeId>(
  x: any,
  typeId?: T
): x is Entry<T> => {
  if (!x || typeof x !== 'object' || typeof x.__typename !== 'string') return false;

  const [theSection, interfaceType] = `${x.__typename}`.split('_');

  if (interfaceType !== 'Entry') return false;
  if (typeId && typeId !== theSection) return false;

  return true;
};
