import { createBlock } from '@/lib/parse/blocks';
import { JobListingType } from './JobListing';

type JobListingsType = {
  listings: JobListingType[];
};

const useJobListingsBlock = createBlock<'blocks_jobListings_BlockType', NoProps, JobListingsType>(
  ({ children = [] }) => {
    const listings = children.reduce((results, child) => {
      const item: JobListingType = {
        job: child.job ?? undefined,
        content: child.content ?? undefined,
        link: child.linkCta ?? undefined,
      };

      results.push(item);

      return results;
    }, [] as JobListingType[]);

    return {
      listings,
    };
  }
);

export default useJobListingsBlock;
