import BlockHeader from '@/components/BlockHeader';
import { BlockContainer } from '@/components/Blocks/BlockContainer';
import CardGrid from '@/components/CardGrid';
import ArticleCard from '@/components/EntryCard/EntryCard.Article';
import SkeletonCards from '@/components/Skeleton/SkeletonCards';
import { useLatestEntries } from '@/lib/fetch/useLatestEntries';
import { createBlock } from '@/lib/parse/blocks';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import { useFeaturedNewsBlock } from './useFeaturedNewsBlock';

const FeaturedNewsBlock = createBlock<'blocks_featuredNews_BlockType'>(
  ({ heading, _blockMeta: meta, ...props }) => {
    const curatedItems = useFeaturedNewsBlock(props);
    const { result = [], loading } =
      useLatestEntries(
        { section: 'article', limit: 6, orderBy: 'postDate desc' },
        'article_Entry'
      ) ?? {};
    const items = curatedItems ?? result;

    return (
      <BlockContainer meta={meta} colorTheme="default" cx={{ pY: '8' }}>
        <BlockHeader
          maxWidth="none"
          cx={{ mB: '7' }}
          heading={heading}
          HeadingProps={{ variant: 'h1', cx: { mX: 'auto' } }}
        />
        {loading ? (
          <SkeletonCards
            amount={6}
            md={6}
            rowGutter={false}
            cx={{ rowGap: '7' }}
            SkeletonItems={{
              cx: { flexDirection: 'column', rowGap: '2' },
              items: [
                { variant: 'rounded', height: 250, width: '100%', sx: { bgcolor: 'lightgrey' } },
                { variant: 'rounded', height: 50, width: '75%', sx: { bgcolor: 'lightgrey' } },
                { variant: 'rounded', height: 20, width: '60%', sx: { bgcolor: 'lightgrey' } },
              ],
            }}
          />
        ) : (
          <CardGrid
            rowGutter={false}
            Component={ArticleCard}
            items={items}
            md={6}
            cx={{ rowGap: '7' }}
          />
        )}
        <Flex center cx={{ pT: '8' }}>
          <Btn
            as="a"
            href="/news"
            size="lg"
            variant="pill"
            colored
            iconType="arrow"
            iconSize="lg"
            focus>
            View all News & Insights
          </Btn>
        </Flex>
      </BlockContainer>
    );
  }
);

export default FeaturedNewsBlock;
