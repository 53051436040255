import { useQuery } from '@apollo/client';
import { EntryTypename, parseEntries } from '../parse/entries';
import { ContentByTypeQueryVariables } from '@/__generated__/graphql';
import { ENTRIES_BY_TYPE_QUERY } from '@/gql/queries/entries.gql';
import { useState } from 'react';

export const useLatestEntries = <TName extends EntryTypename>(
  variables: ContentByTypeQueryVariables,
  typeNames: TName[] | TName = [],
  fetchMoreVariables?: ContentByTypeQueryVariables
) => {
  const { data, loading, error, fetchMore } = useQuery(ENTRIES_BY_TYPE_QUERY, {
    variables,
  });

  const [trigger, setTrigger] = useState<boolean>(true);

  const loadMoreEntries = () => {
    if (!data) return;

    fetchMore({
      variables: {
        ...fetchMoreVariables,
        offset: data.entries?.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        if (!fetchMoreResult.entries?.length) {
          setTrigger(false);
        }

        // Deduplicate entries based on a unique identifier, assuming 'id' is the unique key
        const deduplicatedEntries = [
          ...new Map(
            [...(prev.entries || []), ...(fetchMoreResult.entries || [])].map((entry) => [
              entry?.id,
              entry,
            ])
          ).values(),
        ];

        return {
          ...prev,
          entries: deduplicatedEntries,
        };
      },
    });
  };

  if (error) {
    console.log(`An error occurred with the query: ${error.message}`);
    return null;
  }

  const result = parseEntries(data?.entries, typeNames);

  return {
    fetchMore: loadMoreEntries,
    trigger,
    result,
    loading,
    error,
  };
};
