import { createBlock } from '@/lib/parse/blocks';
import { BlockContainer } from '../../BlockContainer';
import DownloadButton from '@/components/DownloadButton';
import Grid from '@/polymorphics/Grid';

const PdfDownloadBlock = createBlock<'blocks_pdfDownload_BlockType'>(
  ({ documentMultiple, _blockMeta: meta }) => {
    return (
      <BlockContainer {...{ meta }}>
        <Grid rowGutter>
          {documentMultiple?.map((document) => (
            <Grid.Col key={document.id} md={6}>
              <DownloadButton document={document} />
            </Grid.Col>
          ))}
        </Grid>
      </BlockContainer>
    );
  }
);

export default PdfDownloadBlock;
