import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/lib/parse/blocks';
import Divider from '@/polymorphics/Divider';
import { rawColorValues } from '@/style/partials/palette.css';
import CardGrid from '../../../CardGrid';
import RelatedArticleCard from '../../../EntryCard/EntryCard.RelatedArticle';
import { BlockContainer } from '../../BlockContainer';
import { useRelatedArticles } from './useCuratedArticles';

const CuratedArticlesBlock = createBlock<'blocks_curatedArticles_BlockType'>(
  ({ _blockMeta: meta, heading, ...props }) => {
    const items = useRelatedArticles(props);

    return (
      <BlockContainer {...{ meta, maxWidth: 'xl' }}>
        <Divider
          cx={{ width: 'full' }}
          style={{ borderColor: rawColorValues.mistBlue }}
          size="sm"
        />
        <BlockHeader
          maxWidth="none"
          cx={{ pY: '6' }}
          heading={heading}
          HeadingProps={{ variant: 'h1' }}
        />
        {items && (
          <CardGrid items={items} Component={RelatedArticleCard} md={4} cx={{ rowGap: '1' }} />
        )}
      </BlockContainer>
    );
  }
);

export default CuratedArticlesBlock;
