import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Image, { ImageProps } from '../Image';
import * as styles from './Card.Inner.css';
import { useCard } from './useCard';

export type CardImageProps = ImageProps & { hover?: boolean };

export const CardImage = ({ ShimProps = {}, hover = true, ...props }: CardImageProps) => {
  const { title, image } = useCard();

  return (
    image && (
      <Image
        alt={title ?? ''}
        {...mergePropsClassName(
          {
            transform: 'landscapeLgCrop',
            ratio: 'landscapeWide',
            ...image,
            ...props,
          },
          styles.image({ hover })
        )}
        ShimProps={mergePropsClassName(ShimProps, styles.imageWrap)}
      />
    )
  );
};
