'use client';

import { BlockView } from '@/lib/parse/blocks';
import React, { createContext } from 'react';

export interface BlocksContext {
  disablePaddingStart?: boolean;
  disablePaddingEnd?: boolean;
  disableContainers?: boolean;
  showAnchorMenu?: boolean;
}

export const BlocksContext = createContext<BlocksContext>({});

export const { Provider: BlocksContextProvider } = BlocksContext;

export const useBlocksContext = () => {
  return React.useContext(BlocksContext) ?? ({} as BlocksContext);
};

export const BlockContext = createContext<BlockView | null>(null);

export const { Provider: BlockProvider } = BlockContext;

export const useBlock = () => {
  return React.useContext(BlockContext) ?? ({} as BlockView);
};
