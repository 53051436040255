import { createBlock } from '@/lib/parse/blocks';
import Btn from '@/polymorphics/Btn';
import { BlockContainer } from '../../BlockContainer';

import BlockHeader from '@/components/BlockHeader';
import Box from '@/polymorphics/Box';
import Flex from '@/polymorphics/Flex';
import Stat from './Stat';
import useStatsBlock from './useStatsBlock';
import * as styles from './Stat.css';

const StatsBlock = createBlock<'blocks_stats_BlockType'>(({ _blockMeta: meta, ...props }) => {
  const { stats } = useStatsBlock(props);
  const { heading, content, linkCta } = props;

  return (
    <BlockContainer {...{ meta }}>
      <Flex cx={{ flexDirection: { mobile: 'column', desktop: 'row' }, rowGap: '6' }}>
        <BlockHeader maxWidth="none" heading={heading} content={content} className={styles.heading}>
          {linkCta && (
            <Btn
              {...linkCta}
              variant="pill"
              colored
              size="lg"
              cx={{ mT: '3' }}
              iconType="arrow"
              iconSize="lg"
              focus
            />
          )}
        </BlockHeader>
        <Box className={styles.stats}>
          {stats.map((stat, idx) => {
            return <Stat key={idx} isLast={idx === stats.length - 1} {...stat} />;
          })}
        </Box>
      </Flex>
    </BlockContainer>
  );
});

export default StatsBlock;
