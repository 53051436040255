import Box from '@/polymorphics/Box';
import Card from '../Card';
import { EntryCardProps } from '../Card/useEntryCard';
import * as styles from './EntryCard.css';

export type ProfileCardProps = EntryCardProps<'profile'>;

const ProfileCard = ({ item, ...props }: ProfileCardProps) => {
  const {
    linkedinUrl: href,
    title,
    firstName,
    lastName,
    jobPosition,
    imageSingle: image,
    description,
  } = item;

  return (
    <Card
      {...props}
      item={{
        href,
        title: firstName + ' ' + lastName ?? title,
        meta: jobPosition ?? '',
        description,
        image,
      }}>
      <Box cx={{ mB: '3' }}>
        <Card.Image ShimProps={{ InnerProps: { className: styles.imageWrap } }} />
      </Box>
      <Card.Body cx={{ mB: '3' }}>
        <Card.Title as="p" variant="h3" font="body" bold />
        <Card.Meta
          as="p"
          bold
          variant="xs"
          color="primaryHighlights"
          uppercase
          letterSpacing="10%"
        />
        <Card.Description />
      </Card.Body>
      <Card.Foot>
        <Card.Cta text="primaryHeading" iconType="externalLink" iconStroke="currentColor" bold>
          Linkedin
        </Card.Cta>
      </Card.Foot>
    </Card>
  );
};

export default ProfileCard;
