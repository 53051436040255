import React from 'react';
import Txt from '@/polymorphics/Txt';
import Box from '@/polymorphics/Box';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Grid from '@/polymorphics/Grid';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '@/lib/parse/blocks';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import BlockHeader from '@/components/BlockHeader';
import { vars } from '@/style/vars.css';

const nbsp = <>&nbsp;</>;

const TableBlock = createBlock<'blocks_table_BlockType'>(
  ({ heading, content, label, table, colorTheme, _blockMeta: meta }) => {
    const colored = toBoolean(colorTheme) || undefined;
    const { columns = [], rows = [] } = table ?? {};

    return (
      <BlockContainer {...{ meta, colorTheme, colorSet: 'primary', colored }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <BlockHeader
              maxWidth="none"
              heading={heading}
              content={content}
              ContentProps={{ cx: { mB: '7' } }}
            />
            {label && (
              <Txt as="h4" variant="h4" font="body" noMargin bold cx={{ pB: '4' }}>
                {label}
              </Txt>
            )}
            <Box bordered>
              <Table>
                <Box as="thead" colorSet="primary">
                  <TableRow>
                    {columns.map(({ align, heading, width }, i) => (
                      <TableCell
                        key={i}
                        sx={{
                          borderColor: vars.color.colors.primaryHeading,
                          textAlign: align ?? 'left',
                          letterSpacing: 'unset',
                          paddingX: vars.space[2],
                          paddingY: vars.space[1],
                          ...(i && {
                            borderLeftWidth: 1,
                            borderLeftStyle: 'solid',
                          }),
                        }}
                        width={width ?? 'auto'}>
                        <Txt variant="md" as="span" bold color="primaryHeading">
                          {heading || nbsp}
                        </Txt>
                      </TableCell>
                    ))}
                  </TableRow>
                </Box>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow key={i}>
                      {row.map((cellContent, j) => (
                        <TableCell
                          key={j}
                          sx={{
                            textAlign: columns[j].align ?? 'left',
                            letterSpacing: 'unset',
                            paddingX: vars.space[2],
                            paddingY: vars.space[1],
                            ...(i < rows.length - 1 && { borderBottomColor: 'divider' }),
                            ...(j && {
                              borderLeftWidth: 1,
                              borderLeftStyle: 'solid',
                              borderLeftColor: vars.color.colors.primaryHeading,
                            }),
                          }}
                          {...(!j && { component: 'th', scope: 'row' })}>
                          <Txt as="span" variant="md">
                            {cellContent || nbsp}
                          </Txt>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default TableBlock;
