import { gql } from '@/__generated__';

export const ALL_URIS_QUERY = gql(`
  query allUris($section: [String], $type: [String], $orderBy: String) {
    entries(section: $section, type: $type, orderBy: $orderBy) {
      id
      uri
      slug
    }
  }
`);

/**
 * Retrieve a single entry
 */
export const ENTRY_BY_URI_QUERY = gql(`
  query contentByUri(
    $site: [String] = "default"
    $uri: [String]
    $slug: [String]
    $section: [String]
    $orderBy: String
    # $seoEnvironment: SeomaticEnvironment
  ) {
    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy) {
      ...entries
    }
  }
`);

export const ENTRY_EXISTS_QUERY = gql(`
  query entryExists($uri: [String], $slug: [String], $id: [QueryArgument]) {
    entry(uri: $uri, id: $id, slug: $slug, limit: 1) {
      __typename
      id
      uri
      url
    }
  }
`);

/**
 * Retrieve a many entries by section
 */
export const ENTRIES_BY_TYPE_QUERY = gql(`
  query contentByType(
    $site: [String] = "default"
    $section: [String]
    $type: [String]
    $orderBy: String
    $limit: Int
    $offset: Int
  ) {
    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, offset: $offset) {
      ...entries
    }
  }
`);
