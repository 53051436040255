import BlockHeader from '@/components/BlockHeader';
import Video from '@/components/Video';
import { createBlock } from '@/lib/parse/blocks';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockContainer } from '../../BlockContainer';

const VideoBlock = createBlock<'blocks_video_BlockType'>(
  ({ videoUrl, heading, colorTheme, _blockMeta: meta }) => {
    const colored = toBoolean(colorTheme) || undefined;

    return (
      <BlockContainer
        {...{
          meta,
          colorTheme,
          colorSet: 'primary',
          colored,
          maxWidth: 'xl',
        }}>
        <BlockHeader
          maxWidth="none"
          cx={{ mB: '7' }}
          heading={heading}
          HeadingProps={{ variant: 'h1', cx: { mX: 'auto' }, textAlign: 'center' }}
        />
        <Video videoUrl={videoUrl} />
      </BlockContainer>
    );
  }
);

export default VideoBlock;
