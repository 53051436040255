import { createBlock } from '@/lib/parse/blocks';
import { IconCardType } from '.';

type IconGridProps = {
  items: IconCardType[];
};

export const useIconGrid = createBlock<'blocks_iconGrid_BlockType', NoProps, IconGridProps>(
  ({ children = [] }) => {
    const items = children.reduce((results, child) => {
      const item: IconCardType = {
        icon: child.icon,
        heading: child.heading ?? undefined,
        content: child.content ?? undefined,
        link: child.linkCta ?? undefined,
      };

      results.push(item);

      return results;
    }, [] as IconCardType[]);

    return {
      items,
    };
  }
);
