'use client';

import { createContext } from 'react';
import { View } from '../fetch/getEntryByUri';

export type AppContext = {
  view?: View | null;
};

export const getAppContext = async (defaults?: Partial<AppContext>): Promise<AppContext> => {
  return {
    ...defaults,
  };
};

export const appContext = createContext<AppContext>({});

export const { Provider: AppContextProvider } = appContext;
