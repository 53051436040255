import { createBlock } from '@/lib/parse/blocks';
import { StatType } from './Stat';

type StatsProps = {
  stats: StatType[];
};

const useStatsBlock = createBlock<'blocks_stats_BlockType', NoProps, StatsProps>(
  ({ children = [] }) => {
    const stats = children.reduce((results, child) => {
      const item: StatType = {
        number: child.number ?? undefined,
        content: child.content ?? undefined,
        link: child.linkCta ?? undefined,
      };

      results.push(item);

      return results;
    }, [] as StatType[]);

    return {
      stats,
    };
  }
);

export default useStatsBlock;
