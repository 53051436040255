import Txt, { TxtProps } from '@/polymorphics/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import * as style from './Card.Inner.css';
import { fmtFullName } from '@/lib/helpers/format';

export type CardTitleProps<D extends React.ElementType = 'h2'> = TxtProps<D>;

export const CardTitle = <D extends React.ElementType>({
  children,
  hover,
  ...props
}: CardTitleProps<D>) => {
  const { firstName, lastName, titleComponent, title } = useCard();

  const fullName = fmtFullName(firstName, lastName);
  const content = fullName || title;

  return (
    <Txt
      as={titleComponent || 'h2'}
      variant="h4"
      {...mergePropsClassName(props, style.title({ hover }))}>
      {children || content}
    </Txt>
  );
};
