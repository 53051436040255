import Txt, { TxtProps } from '@/polymorphics/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import * as style from './Card.Inner.css';
import { useCard } from './useCard';

export type CardMetaProps<D extends React.ElementType = 'h2'> = TxtProps<D>;

export const CardMeta = <D extends React.ElementType>({ children, ...props }: CardMetaProps<D>) => {
  const { meta } = useCard();
  const content = children ?? meta;

  return (
    content && (
      <Txt as="span" {...mergePropsClassName(props, style.meta)}>
        {content}
      </Txt>
    )
  );
};
