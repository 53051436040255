import { createBlock } from '@/lib/parse/blocks';
import { BlockContainer } from '../../BlockContainer';
import Image from '@/components/Image';
import Txt from '@/polymorphics/Txt';

const ImageBlock = createBlock<'blocks_image_BlockType'>(
  ({ imageSingle, imageWidth, label, _blockMeta: meta }) => {
    const isFullWidth = imageWidth === 'fullWidth';
    const isColumnWidth = imageWidth === 'columnWidth';
    const isScreenWidth = imageWidth === 'screenWidth';

    return (
      <BlockContainer
        {...{
          meta,
          maxWidth: isFullWidth ? 'xl' : isColumnWidth ? 'md' : undefined,
          disableContainer: isScreenWidth,
          marginY: isScreenWidth ? 'none' : undefined,
        }}>
        <Image alt="block-image" fixedRatio {...imageSingle} />
        {label && (
          <Txt as="figcaption" variant="sm" cx={{ mT: '2' }}>
            {label}
          </Txt>
        )}
      </BlockContainer>
    );
  }
);

export default ImageBlock;
