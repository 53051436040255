import { createBlock } from '@/lib/parse/blocks';
import { ImageEntry } from '@/lib/parse/images';
import { Link } from '@/lib/parse/linkField';

export type ImageAndTextType = {
  content: React.ReactNode;
  link?: Link;
  image?: ImageEntry;
  flipAlignment: boolean;
  imageType: 'landscape' | 'portrait' | 'default';
};

type ImageAndTextProps = {
  items: ImageAndTextType[];
};

export const useImageAndTextBlock = createBlock<
  'blocks_imageAndText_BlockType',
  NoProps,
  ImageAndTextProps
>(({ children = [] }) => {
  const items = children.reduce((results, child) => {
    const item: ImageAndTextType = {
      image: child.imageSingle ?? undefined,
      content: child.content ?? undefined,
      link: child.linkCta ?? undefined,
      flipAlignment: child.flipAlignment ?? false,
      imageType: (child.imageType as ImageAndTextType['imageType']) ?? 'default',
    };

    results.push(item);

    return results;
  }, [] as ImageAndTextType[]);

  return {
    items,
  };
});
