'use client';

import { useState } from 'react';
import AccordionItem, { AccordionItemType } from './Accordion.Item';
import Box from '@/polymorphics/Box';

export type AccordionProps = {
  items: AccordionItemType[];
};

const Accordion = ({ items = [], ...props }: AccordionProps) => {
  const [currentIdx, setCurrentIdx] = useState(0);

  const btnOnClick = (idx: number) => {
    setCurrentIdx((currentValue) => (currentValue !== idx ? idx : -1));
  };

  return (
    <Box
      as="ul"
      colorTheme="light"
      colorSet="primary"
      colored
      cx={{ padding: 'none', margin: 'none' }}
      {...props}>
      {items.map((item, idx) => (
        <AccordionItem
          key={idx}
          data={item}
          isLast={idx === items.length - 1}
          isOpen={idx === currentIdx}
          btnOnClick={() => btnOnClick(idx)}
        />
      ))}
    </Box>
  );
};

export default Accordion;
