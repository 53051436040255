import Icon, { IconNames } from '@/components/Icon';
import { Link } from '@/lib/parse/linkField';
import React from 'react';
import Box, { BoxProps } from '../../../../polymorphics/Box';
import Btn from '../../../../polymorphics/Btn';
import Txt from '../../../../polymorphics/Txt';
import { vars } from '@/style/vars.css';

export type IconCardType = BoxProps<
  'div',
  {
    icon: IconNames;
    heading?: string;
    content?: React.ReactNode;
    link?: Link;
  }
>;

export default function IconCard({ heading, content, icon, link, ...props }: IconCardType) {
  return (
    <Box {...props}>
      <Btn variant="link" text="primaryHighlights" cx={{ padding: 'none' }}>
        <Icon
          name={icon}
          CustomSvgProps={{ style: { marginBottom: vars.space[3], color: 'inherit' } }}
        />
      </Btn>
      <Txt as="p" bold color="primaryHeading">
        {heading}
      </Txt>
      <Txt html>{content}</Txt>
      {link && (
        <Btn
          iconStroke="currentColor"
          text="primaryHighlights"
          variant="link"
          decoration="none"
          cx={{ mT: '2' }}
          iconType="arrow"
          iconSize="md"
          hoverState
          bold
          {...link}
        />
      )}
    </Box>
  );
}
