import { createBlock } from '@/lib/parse/blocks';
import Txt from '@/polymorphics/Txt';
import { BlockContainer } from '../../BlockContainer';
import * as styles from './QuoteBlock.css';

const QuoteBlock = createBlock<'blocks_quote_BlockType'>(
  ({ content, author, lightswitch: toggleQuotations, _blockMeta: meta }) => {
    let quote = content;

    if (toggleQuotations) quote = `"${quote}"`;

    return (
      <BlockContainer {...{ meta }} className={styles.root}>
        <Txt
          as="blockquote"
          color="primaryHighlights"
          className={styles.blockquote}
          cx={{ mT: 'none', mB: '2', mX: 'none' }}>
          {quote}
        </Txt>
        <Txt as="cite" variant="md" color="primaryHighlights">
          {'—' + ' ' + author}
        </Txt>
      </BlockContainer>
    );
  }
);

export default QuoteBlock;
