import { createBlock } from '@/lib/parse/blocks';
import { useCtaBlock } from './useCtaBlock';
import Cta from './Cta';

const CtaBlock = createBlock<'blocks_ctaBlock_BlockType'>((props) => {
  const items = useCtaBlock(props) ?? [];

  return (
    <>
      {items.map((item, idx) => {
        return <Cta key={idx} {...item} />;
      })}
    </>
  );
});

export default CtaBlock;
