import { GridCol } from './Grid.Col';
import { GridStyleProps, useGridStyle } from './useGridStyle';

import { combineComponentChildren, Polymorphic } from '@/polymorphics/utils';
import Box, { BoxComponent, BoxProps } from '@/polymorphics/Box';

import React from 'react';

type GridBaseProps = GridStyleProps;

export type GridProps<C extends React.ElementType = 'div', P = NoProps> = BoxProps<
  C,
  GridBaseProps & P
>;

export const GridRoot: BoxComponent<'div', GridBaseProps> = React.forwardRef(function Grid<
  C extends React.ElementType
>(props: GridProps<C>, ref?: Polymorphic.Ref<C>) {
  const boxProps = useGridStyle(props) as BoxProps;

  return <Box ref={ref} {...boxProps} />;
});

const Grid = combineComponentChildren(GridRoot, { Col: GridCol });

export default Grid;
