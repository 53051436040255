'use client';

import { createBlock } from '@/lib/parse/blocks';
import { Widget } from '@typeform/embed-react';
import { BlockContainer } from '../../BlockContainer';
import Box from '@/polymorphics/Box';

const TypeformBlock = createBlock<'blocks_typeform_BlockType'>(
  ({ typeformUrl, _blockMeta: meta }) => {
    if (!typeformUrl) return null;

    return (
      <BlockContainer {...{ meta, maxWidth: 'none' }}>
        <Box style={{ height: '50vh' }}>
          <Widget style={{ height: '100%' }} id={typeformUrl} />
        </Box>
      </BlockContainer>
    );
  }
);

export default TypeformBlock;
