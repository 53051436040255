/* eslint-disable @typescript-eslint/no-explicit-any */
import { ImageFragment, ImageSize_AllFragment } from '@/__generated__/graphql';

import {
  deleteUndefined,
  firstNonNullable,
  makeNonNullableArray,
  pickFromUnion,
  toNumber,
  toString,
} from '@liquorice/allsorts-craftcms-nextjs';

// fragment imageSize_thumb on AssetInterface {
//   size_thumb_url: url @transform(transform: "thumb")
//   size_thumb_height: height @transform(transform: "thumb")
//   size_thumb_width: width @transform(transform: "thumb")
// }

export type ImageTransform = {
  url: string;
  height: number;
  width: number;
};

export type ImageTransformType = Extract<
  keyof ImageSize_AllFragment,
  `${string}_url`
> extends `${infer Size}_url`
  ? Size
  : never;

export type ImageTransforms = { [P in ImageTransformType]?: ImageTransform };

export const imageTransformKeys: ImageTransformType[] = [
  'original',
  'bannerCrop',
  'fullFit',
  'landscapeLgCrop',
  'landscapeMdCrop',
  'landscapeSmCrop',
  'squareLgCrop',
  'squareMdCrop',
  'squareSmCrop',
];

export interface ImageEntry {
  id?: string;
  noCrop?: boolean;
  mimeType: string;
  src: string;
  alt?: string;
  title?: string;
  caption?: string;
  height?: number;
  width?: number;
  srcSet?: string;
  transforms?: ImageTransforms;
}

export type ImageFragmentWithTransform = ImageFragment & ImageSize_AllFragment;

export const parseImage = (data: MaybeArrayOf<ImageFragmentWithTransform>): ImageEntry | null => {
  const image = firstNonNullable(data);

  if (!image || !image.url) return null;

  const { id, alt, title, url: src, height, width, mimeType } = pickFromUnion(image, []);

  const imageEntry: ImageEntry = {
    id,
    mimeType: toString(mimeType),
    src,
    height,
    width,
    alt,
    title,
    // caption,
    transforms: imageTransformKeys.reduce((result, transformType) => {
      const url = image[`${transformType}_url`];
      const width = toNumber(image[`${transformType}_width`]);
      const height = toNumber(image[`${transformType}_height`]);

      if (url)
        result[transformType] = {
          url,
          width,
          height,
        };

      return result;
    }, {} as ImageTransforms),
  };

  return deleteUndefined(imageEntry);
};

export const parseImageMany = (maybeImage: MaybeArrayOf<ImageFragmentWithTransform>) => {
  const imageArr = makeNonNullableArray(maybeImage);
  return makeNonNullableArray(imageArr.map(parseImage));
};

export const isSvg = (image?: Maybe<ImageEntry>) => {
  return image?.mimeType === 'image/svg+xml';
};
