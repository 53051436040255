import { View } from '../fetch/getEntryByUri';
import { appContext } from './context';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { filterBlocks, parseSanitisedBlocks, SanitisedBlock } from '../parse/blocks';
import { useContext } from 'react';

export const useView = () => {
  const { view } = useContext(appContext) ?? {};
  return view ?? ({} as Partial<View>);
};

export const useViewBlocks = () => {
  const { view } = useContext(appContext) ?? {};

  const blocks = maybeGet(view, 'blocks') ?? [];

  const parsedBlocks = parseSanitisedBlocks(blocks as SanitisedBlock[]);

  return filterBlocks(parsedBlocks);
};
