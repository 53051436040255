import CardGrid from '@/components/CardGrid';
import ProfileCard from '@/components/EntryCard/EntryCard.Profile';
import { createBlock } from '@/lib/parse/blocks';
import { BlockContainer } from '../../BlockContainer';
import { useLeadershipTeamBlock } from './useLeadershipTeamBlock';
const LeadershipTeamBlock = createBlock<'blocks_leadershipTeam_BlockType'>(
  ({ _blockMeta: meta, ...props }) => {
    const items = useLeadershipTeamBlock(props);

    const leaders = items?.slice(0, 2);
    const rest = items?.slice(2);

    return (
      <BlockContainer {...{ meta }}>
        {leaders && (
          <CardGrid
            items={leaders}
            Component={ProfileCard}
            md={6}
            rowGutter={false}
            cx={{ rowGap: '6', mB: '6' }}
          />
        )}
        {rest && (
          <CardGrid
            items={rest}
            Component={ProfileCard}
            md={4}
            rowGutter={false}
            cx={{ rowGap: '6' }}
          />
        )}
      </BlockContainer>
    );
  }
);

export default LeadershipTeamBlock;
