import Icon from '@/components/Icon';
import Image from '@/components/Image';
import { createBlock } from '@/lib/parse/blocks';
import { ImageEntry } from '@/lib/parse/images';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import Grid from '@/polymorphics/Grid';
import Txt from '@/polymorphics/Txt';
import { Modal } from '@mui/material';
import { useState } from 'react';
import { BlockContainer } from '../../BlockContainer';
import * as styles from './MediaGalleryBlock.css';

const MediaGalleryBlock = createBlock<'blocks_mediaGallery_BlockType'>(
  ({ heading, imageMultiple, _blockMeta: meta }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [hoverImage, setImage] = useState<ImageEntry | undefined>(undefined);

    const hoverImageWidth = hoverImage?.width ?? 1920;
    const hoverImageHeight = hoverImage?.height ?? 1080;

    const handleClose = () => {
      setOpen(false);
    };

    const handleImage = (value: ImageEntry) => {
      setImage(value);
      setOpen(true);
    };

    return (
      <BlockContainer {...{ meta }}>
        <Grid cx={{ mB: '7' }}>
          {heading && (
            <Grid.Col md={12}>
              <Txt as="p" variant="h1" textAlign="center">
                {heading}
              </Txt>
            </Grid.Col>
          )}
        </Grid>
        <Grid rowGutter>
          {imageMultiple?.map((image) => {
            return (
              <Grid.Col key={image.id} md={4}>
                <Image
                  curved
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleImage(image)}
                  alt={image?.alt || 'Media image'}
                  transform="landscapeLgCrop"
                  {...image}
                />
              </Grid.Col>
            );
          })}
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none' }}>
          <Flex
            justify="center"
            align="center"
            colored={false}
            className={styles.modal({
              wide: hoverImageWidth > hoverImageHeight,
              tall: hoverImageHeight > hoverImageWidth,
            })}>
            <Btn onClick={handleClose} className={styles.closeIcon} variant="circle">
              <Icon name="close" />
            </Btn>
            <Image
              src={hoverImage?.src}
              alt={hoverImage?.alt || 'modal-image'}
              transform="original"
              className={styles.image({
                wide: hoverImageWidth > hoverImageHeight,
                tall: hoverImageHeight > hoverImageWidth,
              })}
            />
          </Flex>
        </Modal>
      </BlockContainer>
    );
  }
);

export default MediaGalleryBlock;
