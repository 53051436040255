import { Table_TableMakerField } from '@/__generated__/graphql';
import { stripHtmlTags } from '@/lib/helpers/htmlHelpers';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';

export const sanitiseHeading = (maybeHeading?: Maybe<string>) => {
  return stripHtmlTags(maybeHeading, ['strong', 'br']);
};

export const parseTable = (data?: Maybe<Table_TableMakerField>) => {
  if (!data) return null;

  const { columns: maybeColumns, rows: maybeRows } = data;
  // const align: ('left' | 'right' | 'center')[] = ['left', 'right', 'center'];
  const columns = makeNonNullableArray(maybeColumns);
  const rows = makeNonNullableArray(maybeRows);

  return {
    columns,
    rows,
  };
};

export const parseHeaderType = (headerType: Maybe<string>) => {
  switch (headerType) {
    case 'overviewPortrait':
      return 'overviewPortrait';
    case 'overviewLandscape':
      return 'overviewLandscape';
    case 'simple':
      return 'simple';
    case 'banner':
      return 'banner';
    case 'heading':
      return 'heading';
    case 'default':
      return 'default';
    default:
      return 'default';
  }
};

export const parseImageType = (imageType: Maybe<string>) => {
  switch (imageType) {
    case 'default':
      return 'default';
    case 'landscape':
      return 'landscape';
    case 'portrait':
      return 'portrait';
    default:
      return 'default';
  }
};

export const parseImageWidth = (imageWidth: Maybe<string>) => {
  switch (imageWidth) {
    case 'fullWidth':
      return 'fullWidth';
    case 'columnWidth':
      return 'columnWidth';
    case 'screenWidth':
      return 'screenWidth';
    default:
      return 'fullWidth';
  }
};

export const parseIcon = (icon: Maybe<string>) => {
  switch (icon) {
    case 'download':
      return 'download';
    case 'boxFreshSmartPhone':
      return 'boxFreshSmartPhone';
    case 'breakItDown':
      return 'breakItDown';
    case 'buyUsed':
      return 'buyUsed';
    case 'challengerSpirit':
      return 'challengerSpirit';
    case 'circularEconomy':
      return 'circularEconomy';
    case 'connectivity':
      return 'connectivity';
    case 'contract':
      return 'contract';
    case 'creativeAgility':
      return 'creativeAgility';
    case 'customerExperience':
      return 'customerExperience';
    case 'customerSupport':
      return 'customerSupport';
    case 'damagedDevice':
      return 'damagedDevice';
    case 'different':
      return 'different';
    case 'differentiation':
      return 'differentiation';
    case 'elegantSimplicity':
      return 'elegantSimplicity';
    case 'increasedProfitability':
      return 'increasedProfitability';
    case 'innovation':
      return 'innovation';
    case 'marketLeadership':
      return 'marketLeadership';
    case 'netAdds':
      return 'netAdds';
    case 'partsForRepair':
      return 'partsForRepair';
    case 'practicalMagic':
      return 'practicalMagic';
    case 'premiumUsedDevices':
      return 'premiumUsedDevices';
    case 'sellUsedDevices':
      return 'sellUsedDevices';
    case 'smartphoneUse':
      return 'smartphoneUse';
    case 'smartwatch':
      return 'smartwatch';
    case 'steadySupply':
      return 'steadySupply';
    case 'streamline':
      return 'streamline';
    case 'sustainability':
      return 'sustainability';
    case 'tablet':
      return 'tablet';
    case 'takesTenMinutes':
      return 'takesTenMinutes';
    case 'totalAlignment':
      return 'totalAlignment';
    case 'tradeIn':
      return 'tradeIn';
    case 'upgrades':
      return 'upgrades';
    case 'whatsTheCatch':
      return 'whatsTheCatch';
    default:
      return 'boxFreshSmartPhone';
  }
};
