import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/lib/parse/blocks';
import Flex from '@/polymorphics/Flex';
import { BlockContainer } from '../../BlockContainer';
import JobListing from './JobListing';
import useJobListingsBlock from './useJobListingsBlock';

const JobListingsBlock = createBlock<'blocks_jobListings_BlockType'>(
  ({ _blockMeta: meta, ...props }) => {
    const { listings } = useJobListingsBlock(props);
    const { heading, content } = props;

    return (
      <BlockContainer {...{ meta }}>
        <BlockHeader
          maxWidth="none"
          heading={heading}
          HeadingProps={{ variant: 'h1' }}
          content={content}
        />
        <Flex cx={{ flexDirection: 'column', mT: '3' }}>
          {listings.map((listing, idx) => {
            return <JobListing key={idx} isLast={idx === listings.length - 1} {...listing} />;
          })}
        </Flex>
      </BlockContainer>
    );
  }
);

export default JobListingsBlock;
