import { toNumber } from '@liquorice/allsorts-craftcms-nextjs';

export const fmtPercent = (n?: string | number | null) => {
  return `${toNumber(n)}%`;
};

export const fmtMoney = (n?: string | number | null) => {
  return `$${toNumber(n)}`;
};

export const floatPoint = (n: string | number | null) => {
  return toNumber(parseFloat(`${toNumber(n)}`).toPrecision(12));
};

export const roundToTwo = (n: string | number | null) => {
  return Math.round(toNumber(n) * 100) / 100;
};

export const numberedLabel = (n: number | undefined, name: string, nameSingular: string) =>
  (Math.abs(n ?? 0) === 1 ? nameSingular : name).replace('%n', `${n ?? 0}`);

export const removeOrphan = (str: string, charLim = 10) => {
  const lastIndex = str.lastIndexOf(' ');

  if (lastIndex < 1) return str;

  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + 1);

  if (after.length > charLim) return str;

  return (
    <>
      {before}&nbsp;{after}
    </>
  );
};

export const fmtProperString = (maybeString: string) => {
  return maybeString.charAt(0).toUpperCase() + maybeString.slice(1);
};

export const fmtFullName = (
  honorific?: string | null,
  firstName?: string | null,
  lastName?: string | null
) => {
  if (!firstName || !lastName) return null;

  return `${honorific ?? ''} ${firstName ?? ''} ${lastName ?? ''}`;
};
