import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import Icon from '../Icon';

import * as styles from './DownloadButton.css';
import { BtnProps } from '@/polymorphics/Btn';
import { DocumentAsset } from '@/lib/parse/common/document';
import Box from '@/polymorphics/Box';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';

export type DownloadButtonProps = BtnProps & {
  title?: string;
  document?: Partial<DocumentAsset>;
};

const DownloadButton = ({ document = {}, title: customTitle, ...props }: DownloadButtonProps) => {
  const { url: href, extension, sizeHuman } = document;

  const title = customTitle ?? document.title;

  const ext = extension ? extension.toUpperCase() : null;
  const size = sizeHuman ?? null;
  const meta = makeNonNullableArray([ext, size]);

  const metaText = `(${meta.join(', ')})`.toUpperCase();
  const ariaLabel = `${title} ${metaText}`;

  return !href ? (
    <></>
  ) : (
    <Box
      colorTheme="mistBlue"
      colorSet="primary"
      colored={true}
      as="a"
      download={'download'}
      aria-label={ariaLabel}
      href={href}
      target="_blank"
      className={styles.root}
      {...props}>
      <Flex className={styles.inner} colored={false}>
        <Flex className={styles.icon} colored={false}>
          <Icon name="download" />
        </Flex>
        <Flex direction="column" cx={{ rowGap: '2', width: 'full' }} colored={false}>
          <Txt as="span" variant="sm" font="body" bold>
            {title}
          </Txt>
          <Flex justify="between" colored={false}>
            <Txt as="span" variant="sm">
              {ext}
            </Txt>
            <Txt as="span" variant="sm">
              {size}
            </Txt>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default DownloadButton;
