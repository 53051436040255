import Box, { BoxProps } from '@/polymorphics/Box';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import * as styles from './Card.Inner.css';
import { CardTitle } from './Card.Title';
import { CardDescription } from './Card.Description';

export type CardBodyProps = BoxProps<'div'>;

export const CardBody = ({ children, ...props }: CardBodyProps) => {
  return (
    <Box {...props} {...mergePropsClassName(props, styles.body)}>
      {children || (
        <>
          <CardTitle />
          <CardDescription />
        </>
      )}
    </Box>
  );
};
