import { createBlock } from '@/lib/parse/blocks';
import { AccordionItemType } from '../../../Accordion/Accordion.Item';

type AccordionBlockProps = {
  items: AccordionItemType[];
};

export const useAccordionBlock = createBlock<
  'blocks_accordion_BlockType',
  NoProps,
  AccordionBlockProps
>(({ children = [] }) => {
  const items = children.reduce((results, child) => {
    const item: AccordionItemType = {
      heading: child.heading ?? undefined,
      content: child.content ?? undefined,
    };

    results.push(item);

    return results;
  }, [] as AccordionItemType[]);

  return {
    items,
  };
});
