'use client';

import { createBlock } from '@/lib/parse/blocks';
import Txt from '@/polymorphics/Txt';
import { BlockContainer } from '../../BlockContainer';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import Btn from '@/polymorphics/Btn';

const RichTextBlock = createBlock<'blocks_richText_BlockType'>(
  ({ content, linkCta, colorTheme, _blockMeta: meta }) => {
    const colored = toBoolean(colorTheme) || undefined;

    return (
      <BlockContainer {...{ meta, colorTheme, colorSet: 'primary', colored, maxWidth: 'md' }}>
        <Txt html>{content}</Txt>
        {linkCta && (
          <Btn
            cx={{ mT: '5' }}
            variant="pill"
            colorTheme={colorTheme}
            colorSet="primary"
            colored
            size="lg"
            iconType="arrow"
            iconSize="lg"
            focus
            {...linkCta}
          />
        )}
      </BlockContainer>
    );
  }
);

export default RichTextBlock;
