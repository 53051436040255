import { recursiveTrimNonTruthy } from './recursiveTrimNonTruthy';

import {
  SeoJsonLdFragment,
  SeoMetaLinksFragment,
  SeoMetaTagsFragment,
  SeoScriptsFragment,
  SeoSiteVarsFragment,
  SeoTitleFragment,
} from '@/__generated__/graphql';

import { Metadata } from 'next';
import { ScriptProps } from 'next/script';
import { ComponentProps } from 'react';

type SeomaticMetaTag = {
  content: string;
  property?: string;
  name?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSeomaticMetaTag = (item: any): item is SeomaticMetaTag => {
  return item?.content && (item?.property || item?.name);
};

// ------------------------------------------------------------------------------------------------
// ---- Types ----

type SeoContainers =
  | SeoMetaLinksFragment
  | SeoMetaTagsFragment
  | SeoScriptsFragment
  | SeoJsonLdFragment
  | SeoSiteVarsFragment;

export const parseSeoMetaLinks = (maybeData: Maybe<SeoMetaLinksFragment>) => {
  const data = parseSeoContainerJson(maybeData);
  return data;
};

export const parseSeoTitle = (maybeData: Maybe<SeoTitleFragment>): string | null => {
  const data = parseSeoContainerJson(maybeData);
  return data?.title?.title || null;
};

export const parseSeoMetaTags = (maybeData: Maybe<SeoMetaTagsFragment>): Metadata => {
  const data = parseSeoContainerJson(maybeData);
  const tagSource = Object.values(data).filter(isSeomaticMetaTag);
  const tags = tagSource.reduce((result, item) => {
    const { content, property, name = property } = item;
    if (name) result[name] = content;
    return result;
  }, {} as Record<string, string>);

  return { other: tags };
};

export const parseSeoScripts = (maybeData: Maybe<SeoScriptsFragment>) => {
  const data = parseSeoContainerJson(maybeData);

  const scripts = Object.entries(data).reduce(
    (result, [key, item]) => {
      const { script, bodyScript } = item as {
        script?: string;
        bodyScript?: string;
      };

      script &&
        result.scripts.push({
          id: `${key}-script`,
          type: 'text/javascript',
          dangerouslySetInnerHTML: {
            __html: script,
          },
        });

      bodyScript &&
        result.bodyScripts.push({
          id: `${key}-bodyScript`,

          dangerouslySetInnerHTML: {
            __html: bodyScript,
          },
        });

      return result;
    },
    {
      scripts: [] as ScriptProps[],
      bodyScripts: [] as ComponentProps<'span'>[],
    }
  );

  return scripts;
};

export const parseSeoJsonLd = (maybeData: Maybe<SeoJsonLdFragment>): ScriptProps => {
  const data = parseSeoContainerJson(maybeData);
  const scriptContents = JSON.stringify({
    '@context': 'http://schema.org',
    '@graph': Object.values(data),
  });

  return {
    id: 'seo-json-ld',
    type: 'application/ld+json',
    dangerouslySetInnerHTML: {
      __html: scriptContents,
    },
  };
};

export const parseSeoSiteVars = (maybeData: Maybe<SeoSiteVarsFragment>) => {
  const data = parseSeoContainerJson(maybeData);
  return data;
};

export const parseSeoContainerJson = <T extends SeoContainers>(maybeData: Maybe<T>) => {
  try {
    const parsedValue = maybeData && typeof maybeData === 'string' ? JSON.parse(maybeData) : null;
    return recursiveTrimNonTruthy(parsedValue);
  } catch (error) {
    console.error(error);
    return null;
  }
};
