import Btn, { BtnComponent, BtnProps } from '@/polymorphics/Btn';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import * as styles from './Card.Inner.css';

export type CardCtaProps<D extends React.ElementType = 'span'> = BtnProps<D>;

const CardCta: BtnComponent = <D extends React.ElementType>({
  iconType,
  children,
  ...props
}: CardCtaProps<D>) => {
  // const { href } = useCard();

  return (
    <Btn
      as="button"
      text="primaryHighlights"
      hoverState
      variant="link"
      cx={{ pX: 'none', cursor: 'pointer' }}
      iconType={iconType ?? 'arrow'}
      iconSize="md"
      {...mergePropsClassName(props, styles.cta)}>
      {children || 'Read more'}
    </Btn>
  );
};

export default CardCta;
