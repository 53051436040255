import React from 'react';
import Txt from '@/polymorphics/Txt';
import * as styles from './Stat.css';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import { Link } from '@/lib/parse/linkField';
import Box from '@/polymorphics/Box';

export type StatType = {
  number?: string;
  content?: string;
  link?: Link;
};

export type BorderType = {
  isLast: boolean;
};

export default function Stat(props: StatType & BorderType) {
  const { number, content, isLast, link } = props;

  return (
    <Flex
      cx={{ columnGap: '4', rowGap: '2', flexDirection: { mobile: 'column', tablet: 'row' } }}
      className={styles.root({ isLast })}>
      <Box style={{ minWidth: '40%' }}>
        <Txt
          as="p"
          variant="h2"
          color="primaryHighlights"
          // Custom font override
          style={{ fontSize: '4.5rem', lineHeight: '4.5rem' }}>
          {number}
        </Txt>
      </Box>
      <Box>
        <Txt as="p" variant="xl" bold noMargin>
          {content}
        </Txt>
        {link && (
          <Flex align="center" cx={{ mT: { mobile: '1', tablet: '2' }, columnGap: '0.5' }}>
            <Txt as="p" variant="xs" noMargin>
              Source:
            </Txt>
            <Btn as="a" href={link.href} variant="link" decoration="underline">
              <Txt variant="xs">{link.children}</Txt>
            </Btn>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
