import { createBlock } from '@/lib/parse/blocks';
import Box from '@/polymorphics/Box';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockContainer } from '../../BlockContainer';
import * as styles from './Testimonial.css';

const TestimonialBlock = createBlock<'blocks_testimonial_BlockType'>(
  ({ quote, firstName, lastName, jobPosition, colorTheme, _blockMeta: meta }) => {
    const colored = toBoolean(colorTheme) || undefined;

    return (
      <BlockContainer {...{ meta, colorTheme, colorSet: 'primary', colored }}>
        <Flex center>
          <Txt
            as="h3"
            variant="h3"
            cx={{ width: { desktop: 'threeFifths' }, mB: 'none' }}
            className={styles.quote}>
            {quote}
          </Txt>
          <Box cx={{ mT: '4' }}>
            <Txt textAlign="center" as="p" variant="md" color="primaryHeading" bold noMargin>
              {firstName} {lastName}
            </Txt>
            <Txt
              textAlign="center"
              as="p"
              variant="xs"
              color="primaryHeading"
              uppercase
              letterSpacing="10%"
              bold>
              {jobPosition}
            </Txt>
          </Box>
        </Flex>
      </BlockContainer>
    );
  }
);

export default TestimonialBlock;
