import { Link } from '@/lib/parse/linkField';
import Box from '@/polymorphics/Box';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import React from 'react';
import * as styles from './JobListing.css';

export type isLast = { isLast: boolean };

export type JobListingType = {
  job?: string;
  content?: React.ReactNode;
  link?: Link;
};

export default function JobListing(props: JobListingType & isLast) {
  const { job, content, link, isLast } = props;

  return (
    <Flex
      className={styles.root({ isLast })}
      cx={{
        pY: '4',
        columnGap: '2',
        rowGap: '2',
        flexDirection: { mobile: 'column', desktop: 'row' },
      }}>
      <Txt as="p" variant="h4" bold noMargin cx={{ width: { desktop: 'quarter' } }}>
        {job}
      </Txt>
      <Box className={styles.content} cx={{ width: { desktop: 'half' } }}>
        <Txt html>{content}</Txt>
      </Box>
      <Box cx={{ mL: { desktop: 'auto' } }}>
        {link && (
          <Btn
            {...link}
            as="a"
            type="link"
            variant="pill"
            colored
            iconType="outwardArrow"
            iconSize="lg"
            size="lg"
            href={link.href}
            focus
          />
        )}
      </Box>
    </Flex>
  );
}
