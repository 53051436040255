import { BlockContainer } from '@/components/Blocks/BlockContainer';
import { createBlock } from '@/lib/parse/blocks';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import * as styles from './HeroStatementBlock.css';
import Image from '@/components/Image';

export type HeroStatementProps = {
  heading: string;
  content: React.ReactNode;
};

const HeroStatementBlock = createBlock<'blocks_heroStatement_BlockType'>(
  ({ heading, content, colorTheme, bgImage, _blockMeta: meta }) => {
    const isImage = toBoolean(bgImage);
    const colored = !isImage ? toBoolean(colorTheme) || undefined : undefined;

    return (
      <BlockContainer
        {...{
          meta,
          colorTheme,
          colorSet: 'primary',
          colored,
          style: { position: 'relative', backgroundColor: isImage ? 'transparent' : undefined },
          ContainerProps: { colored: false },
        }}>
        <Image fixedRatio alt="bg-image" {...bgImage} className={styles.bgImage} />
        <Flex
          colored={false}
          center
          cx={{ mX: 'auto', textAlign: 'center' }}
          style={{ zIndex: '2', position: 'relative' }}>
          <Txt as="p" variant="h2" className={styles.heading}>
            {heading}
          </Txt>
          <Txt html className={styles.content}>
            {content}
          </Txt>
        </Flex>
      </BlockContainer>
    );
  }
);

export default HeroStatementBlock;
