'use client';

import Box from '@/polymorphics/Box';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import Icon from '../Icon';
import * as styles from './Accordion.Item.css';

export type AccordionItemType = {
  heading?: string;
  content?: React.ReactNode;
};

export type AccordionItemProps = {
  data: AccordionItemType;
  isOpen: boolean;
  isLast: boolean;
  btnOnClick: () => void;
} & HTMLAttributes<HTMLLIElement>;

function AccordionItem({ data, isOpen, isLast, btnOnClick, style }: AccordionItemProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const { heading, content } = data;

  useEffect(() => {
    if (isOpen) {
      const contentEl = contentRef.current as HTMLDivElement;
      setHeight(contentEl?.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <Box as="li" className={styles.root({ isLast })} style={style}>
      <Btn type="button" variant="link" width="full" cx={{ pX: 'none' }} onClick={btnOnClick}>
        <Flex justify="between" align="center" cx={{ width: 'full', pY: '3', cursor: 'pointer' }}>
          <Txt as="span" variant="md" bold noMargin className={styles.title({ isOpen })}>
            {heading}
          </Txt>
          {isOpen ? (
            <Icon cx={{ height: '3', width: '3' }} name="minus" />
          ) : (
            <Icon cx={{ height: '3', width: '3' }} name="plus" />
          )}
        </Flex>
      </Btn>
      <Box style={{ maxHeight: height, transition: 'all 250ms ease' }}>
        <div ref={contentRef} className={styles.content}>
          <Txt html>{content}</Txt>
        </div>
      </Box>
    </Box>
  );
}

export default AccordionItem;
