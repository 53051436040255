import Box from '@/polymorphics/Box';
import Card from '../Card';
import { EntryCardProps } from '../Card/useEntryCard';
import * as styles from './EntryCard.css';

export type RelatedArticleCardProps = EntryCardProps<'article'>;

const RelatedArticleCard = ({ item, ...props }: RelatedArticleCardProps) => {
  const { uri: href, title, pageTitle, summary: description, featuredImage: image } = item;

  return (
    <Card
      {...{ fullHeight: false, props }}
      item={{
        href,
        title: pageTitle ?? title,
        description,
        image,
      }}>
      <Box cx={{ mB: '4' }}>
        <Card.Image ShimProps={{ InnerProps: { className: styles.imageWrap } }} />
      </Box>
      <Card.Body cx={{ mB: '3' }}>
        <Card.Title variant="h5" font="body" bold />
        <Card.Description />
      </Card.Body>
      <Card.Foot>
        <Card.Cta />
      </Card.Foot>
    </Card>
  );
};

export default RelatedArticleCard;
