/* eslint-disable @typescript-eslint/no-explicit-any */

import { BlocksFragment, EntriesFragment } from '@/__generated__/graphql';
import { useColorTheme } from '@/style/utils/useColorTheme';
import {
  createPropSanitiser,
  SanitiserTypes,
  toBoolean,
  toId,
  toString,
  toStringOrNull,
  trimSlashes,
  Typename,
} from '@liquorice/allsorts-craftcms-nextjs';
import { cleanHtml } from '../helpers/htmlHelpers';
import {
  parseDocumentMany,
  parseHeaderType,
  parseIcon,
  parseImageType,
  parseImageWidth,
  parseTable,
} from './common';
import { RawGlobals } from './globals/globals';
import { parseImage, parseImageMany } from './images';
import { parseLinkField, parseLinkFields } from './linkField';
import {
  parseSeoJsonLd,
  parseSeoMetaLinks,
  parseSeoMetaTags,
  parseSeoScripts,
  parseSeoSiteVars,
  parseSeoTitle,
} from './seo/seo';

/**
 * Union of all Typed Elements that may be passed to the sanitiser
 */
type RawElements = BlocksFragment | EntriesFragment | RawGlobals;

/**
 * __typename of top level Elements
 */
type ElementTypename = Typename<RawElements>;

// ----------------------------------------------------------------------------------------------------
// --- Define the callbacks ---

export const sanitiseAnything = createPropSanitiser((): RawElements | null => null, {
  content: cleanHtml,
  firstName: toStringOrNull,
  flipAlignment: toBoolean,
  heading: toStringOrNull,
  headerType: parseHeaderType,

  featuredImage: parseImage,
  imageWidth: parseImageWidth,
  imageType: parseImageType,
  imageSingle: parseImage,
  imageMultiple: parseImageMany,
  documentMultiple: parseDocumentMany,
  bgImage: parseImage,
  headerLogo: parseImage,
  bannerImage: parseImage,
  entryImage: parseImage,
  logo: parseImage,
  typeformUrl: toStringOrNull,
  table: parseTable,
  lightswitch: toBoolean,

  number: toStringOrNull,
  id: toId,
  label: toStringOrNull,
  lastName: toStringOrNull,
  linkCta: parseLinkField,
  linkCtaMultiple: parseLinkFields,
  pageTitle: toStringOrNull,
  title: toStringOrNull,
  summary: toStringOrNull,
  websiteUrl: toStringOrNull,
  uri: (value?: string | null) => (value ? `/${trimSlashes(value)}/` : ''),
  slug: toString,
  colorTheme: useColorTheme,
  icon: parseIcon,

  seoJson: 'sanitiseSingle',

  metaLinkContainer: parseSeoMetaLinks,
  metaTagContainer: parseSeoMetaTags,
  metaTitleContainer: parseSeoTitle,
  metaScriptContainer: parseSeoScripts,
  metaJsonLdContainer: parseSeoJsonLd,
  metaSiteVarsContainer: parseSeoSiteVars,

  // colorTheme: 'sanitise',
  ctaBlock: 'sanitise',

  /**
   * Recursively sanitise child elements
   */
  blocks: 'sanitise',
  children: 'sanitise',
  globalSets: 'sanitise',
  partnerMultiple: 'sanitise',
  articleMultiple: 'sanitise',
  profileMultiple: 'sanitise',
  articleSingle: 'sanitiseSingle',
});

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

type SanitiserReturnMap = SanitiserTypes<typeof sanitiseAnything, 'ReturnMap'>;

export type SanitisedElement<T extends ElementTypename = ElementTypename> = SanitiserReturnMap[T];

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isSanitisedElement = <T extends ElementTypename>(
  x: any,
  typename: T
): x is SanitisedElement<T> => {
  return !!x && x.__typename === typename;
};
